import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"

const Container = styled.div`
  text-align: center;
  margin-top: 5px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const OuterContainer = styled.div`
  display: flex;
  align-items: left;
  justify-content: space-around;
  flex-direction: row;
  padding-bottom: 1px;
`


const NameHeader = styled.h1`
  
`

const LandingBio = () => (
  <StaticQuery
    query={graphql`
      query LandingSiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div>
      <OuterContainer>
        <Container style = {{flex: "5"}}>
          <NameHeader>Devashish</NameHeader>
          {/* <p class="landingDescription">I am a Product Manager/Full-Stack Developer at an early stage start-up, FinBox. We provide a data platform that enables people to connect their data with financial institutions. Millions of people use FinBox products to access financial services that they otherwise wouldn’t be able to. </p>
          <p class="landingDescription">I am a stand-up comedian. Comedy can be silly and light-hearted, but it can also provide a new, twisted, and unique spin to emotional arcs that humans go through during various experiences of life. I try to capture those. </p>
          <p class="landingDescription">I make short films. Films are a great way to convey any dilemma you face in life or whatever captures your imagination and fascinates you endlessly.  </p>
          <p class="landingDescription">Some of these films are sketch comedies. Sketch comedies are purely silly. I am absolutely enamored with building and enacting quirky characters that have problems that don’t make sense, and solutions that don’t solve those problems.  </p> */}
         

          <p className="landingDescription descriptionFont">
          I am an independent artist based in Bangalore, India. I do stand-up comedy and have performed in big and small venues in multiple cities around the country. Comedy can be silly and light-hearted, but it can also provide twisted, unique spins on emotional arcs that humans go through during the various experiences of life.

          I also make short films, sketch comedies, and actual wide-body aircrafts. 
          </p>
    

          <p className="landingDescription descriptionFont">But most importantly, I am actually a country. That's why I have a newsletter. Subcribe to my <a href="https://devashishmulye.substack.com/embed" target="_blank">newsletter</a>. Thanks! </p> 




        </Container>
        {/* <img src={jester} alt="Jester" class="center" style={{flex:"1", width:"40%", height:"80%", opacity: "0.95", paddingTop: "10rem"}}/> */}

      </OuterContainer>
      </div>


    )}
  />
)

NameHeader.propTypes = {
  siteTitle: PropTypes.string,
}

NameHeader.defaultProps = {
  siteTitle: ``,
}

export default LandingBio
